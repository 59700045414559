<template>
  <common-dialog
    :show="show"
    :loading="loading"
    @close="syncAndClose"
  >
    <template #header>
      <div>Списания по {{ paymentOrder.payment_doc_number }} от {{ paymentOrder.payment_date | dateFilter }}</div>
      <div class="text-sm-body-1 d-flex">
        Сумма ПП <div class="teal lighten-2 rounded-pill px-3 mx-3">
          {{ prettifyNumber(paymentOrder.sum) }} {{ paymentOrder.currency_letter }}
        </div>
      </div>
    </template>
    <template #body>
      <v-data-table
        :items="paymentOrderDebitings"
        :headers="headers"
        hide-default-footer
        height="450"
        fixed-header
      >
        <template #[`item.index`]="{index}">
          {{ index+1 }}
        </template>
        <template #[`item.declaration_id`]="{item}">
          <div
            v-if="item.declaration_id"
            class="teal--text cursor-pointer d-flex align-center"
            @click="openDocument(item.declaration_id)"
          >
            <v-icon small>
              mdi-open-in-new
            </v-icon> <span class="px-2">{{ item.declaration_id }}</span>
          </div>
          <span v-else>Ручное списание</span>
        </template>
        <template #[`item.payment_date`]>
          <span>{{ paymentOrder.payment_date | dateFilter }}</span>
        </template>
        <template #[`item.actions`]="{item}">
          <debit-table-actions
            :item="item"
            @fetch="fetchPaymentOrderDetails"
          />
        </template>
      </v-data-table>
      <div class="text-lg-body-1 d-flex align-center">
        Остаток  <div class="grey lighten-2 rounded-pill px-4 py-2 mx-3">
          {{ prettifyNumber(paymentOrder.remains) }} {{ paymentOrder.currency_letter }}
        </div>
      </div>
    </template>
  </common-dialog>
</template>
<script>
import CommonDialog from "@/components/shared/common-dialog.vue";
import moment from "moment";
import DebitTableActions from "@/components/payment-orders/debit-table-actions.vue";
import {prettifyNumber} from "@/helpers/math";
import {paymentOrderDefaultState} from "@/helpers/payment-order"
import {fetchPaymentOrdersInjector} from "@/helpers/injectors";

function paymentOrder(){
  return {
    ...paymentOrderDefaultState(),
    payment_order_debitings:[]
  }
}
export default {
  components:{
    DebitTableActions,
    CommonDialog,
  },
  filters:{
    dateFilter(date){
      return moment(date, 'YYYY-MM-DD').isValid() ? moment(date, 'YYYY-MM-DD').format("DD.MM.YYYY") : null
    },
  },
  inject:{
    fetchPaymentOrders:{
      from:fetchPaymentOrdersInjector
    }
  },
  props:{
    show:{
      required:true,
      type:Boolean
    },
    params:{
      required: true,
      type:Object
    }
  },
  data(){
    return {
      loading:false,
      paymentOrder: paymentOrder(),
      headers: [
        {
          text: "№",
          value: "index"
        },
        {
          text: "Дата ПП",
          value: "payment_date"
        },
        {
          text: "Сумма",
          value: "sum"
        },
        {
          text: "Вид платежа",
          value: "code"
        },
        {
          text: "Документ",
          value: "declaration_id"
        },
        {
          text: "СП",
          value: "payment_type"
        },
        {
          text: "Примечание",
          value: "remark"
        },
        {
          text: "",
          value: "actions"
        },
      ],
    }
  },
  computed:{
    paymentOrderDebitings(){
      return this.paymentOrder.payment_order_debitings
    }
  },
  created() {
    this.fetchPaymentOrderDetails()
  },
  methods:{
    prettifyNumber,
    setLoading(loading){
      this.loading = loading
    },
    fetchPaymentOrderDetails(){
      this.setLoading(true)
      this.$store.dispatch('dt/getPaymentOrderById', this.params.selectedPaymentOrder.id).then(({data}) => {
        this.paymentOrder = data
      }).catch(() => {
        this.$error()
        this.paymentOrder = paymentOrder()
      }).finally(() => this.setLoading(false))
    },
    openDocument(id){
      const route = this.$router.resolve({
        path: `/dt/${id}`
      });
      window.open(route.href ,'_blank')
    },
    syncAndClose(){
      this.fetchPaymentOrders()
      this.close()
    },
    close(){
      this.$emit("update:show", false)
    }
  }
}
</script>
