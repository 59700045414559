<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        dark
        x-small
        v-bind="attrs"
        tabindex="-1"
        class="elevation-0"
        v-on="on"
      >
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        :disabled="!!item.declaration_id"
        @click="remove(item.id)"
      >
        <v-list-item-title>Удалить</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props:{
    item:{
      required:true,
      type:Object
    }
  },
  methods: {
    remove(id) {
      this.$store.dispatch("dt/deleteDebitPayment", id).then(() => {
        this.$emit("fetch")
      }).catch(() => this.$error())
    },
  }
}
</script>